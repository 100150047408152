const experts = [
  {
    name: "Αντωνίου Όλγα",
    title: "Λογοθεραπεύτρια",
    location: "Σαλαμίνα",
    phone: "6972082623",
    email: "antwniou@hotmail.com",
  },
  {
    name: "Δαχτυλούδη Βασιλική",
    title: "Λογοθεραπεύτρια",
    location: "Ρόδος",
    phone: "6986713876",
    email: "Vaso92@hotmail.com",
  },
  {
    name: "Δόμνογλου Μαρία",
    title: "Μοντεσσοριανή νηπιαγωγός, ειδική παιδαγωγός",
    location: "Θεσσαλονίκη",
    phone: "2310280072",
    email: "mdomnoglou@gmail.com",
  },
  {
    name: "Ιωαννίδου Ελευθερία",
    title: "Λογοθεραπεύτρια",
    location: "Φλώρινα",
    phone: "6947687567",
    email: "ele.ioannidou12@gmail.com",
  },
  {
    name: "Κυρτσούδη Μαρία",
    title: "Μουσικός",
    location: "Θεσσαλονίκη",
    phone: "6984266841",
    email: "music.auditory.processing@gmail.com",
  },
  {
    name: "Λάιου Σοφία",
    title: "Εργοθεραπεύτρια",
    location: "Αθήνα",
    phone: "6943994429",
    email: "sofialaiou@hotmail.gr",
  },
  {
    name: "Μεϊμάρογλου Σοφία",
    title: "Λογοθεραπεύτρια",
    location: "Θεσσαλονίκη",
    phone: "6938826789",
    email: "sophiemeim@gmail.com",
  },
  {
    name: "Μπουτζέτη Θεοδώρα",
    title: "Λογοπεδικός",
    location: "Σαλαμίνα",
    phone: "6978668683",
    email: "Bouthe0d0ra@gmail.com",
  },
  {
    name: "Πιτταρά Λίτσα",
    title: "Εργοθεραπεύτρια",
    location: "Λευκωσία",
    phone: "99592291",
    email: "litsapittara@gmail.com",
  },
  {
    name: "Παπιγκιώτη Λαμπρινή",
    title: "Ειδική παιδαγωγός",
    location: "Ιωάννινα",
    phone: "6978602319",
    email: "lamprinipapigioti@gmail.com",
  },
  {
    name: "Σαββίδης Κωνσταντίνος",
    title: "Λογοθεραπευτής - Μουσικοθεραπευτής",
    location: "Καστοριά, Μεσοποταμία Καστοριάς, Κοζάνη",
    phone: ["2467306962", "2467505330", "24671033588"],
    email: "konstantinos_savvidis@hotmail.com",
  },
  {
    name: "Σκαμνού Μαρία",
    title: "Ειδική Παιδαγωγός",
    location: "Θεσσαλονίκη, Χαλκιδική",
    phone: "6980809931",
    email: "maria.skamnou@gmail.com",
  },
  {
    name: "Σελίνη Ελευθερία",
    title: "Λογοθεραπεύτρια",
    location: "Εύοσμος, Θεσσαλονίκη",
    phone: "6945956162",
    email: "eleftheriasel@gmail.com",
  },
  {
    name: "Τσάλλα Κωνσταντίνα",
    title: "Εργοθεραπεύτρια",
    location: "Πειραιάς",
    phone: "6946973562",
    email: "Nantia_tsa@hotmail.com",
  },
  {
    name: "Τσίντζου Μαγδαληνή",
    title: "Λογοθεραπεύτρια",
    location: "Λάρισα",
    phone: "6942929313",
    email: "drwkaimilw@gmail.com",
  },
  {
    name: "Φρογάκη Ήρα",
    title: "Λογοθεραπεύτρια",
    location: "Βερολίνο",
    phone: "00491639632946",
    email: "irafrogaki@gmail.com",
  },
  {
    name: "Χρηστιάς Κων/νος",
    title: "Δάσκαλος ειδικής αγωγής",
    location: "Καστοριά",
    phone: "6957669184",
    email: "crisprod17@gmail.com",
  },
  {
    name: "Ψάρρης Γεώργιος",
    title: "Λογοθεραπευτής",
    location: "Αθήνα, Πάτρα",
    phone: "6971815623",
    email: "Psarris.georgios@gmail.com",
  },
  {
    name: "Αντωνία Κωνσταντίνου",
    title: "Λογοθεραπεύτρια",
    location: "Αθήνα",
    phone: "6942427880",
    email: "toniakonnou@hotmail.com",
  },
  {
    name: "Κεραμάρη Ευλαμπία",
    title: "Νηπιαγωγός- Ειδική Παιδαγωγός",
    location: "Θεσσαλονίκη-Γιαννιτσά",
    phone: "6977375215",
    email: "olympi-tsa@hotmail.com",
  },
];

export default experts;
